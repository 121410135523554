import { v4 } from 'uuid';

import { pdfThumbnail } from '@/core/libs/pdfThumbnail';
import staticIntl from '@/core/libs/static-intl';
import { toast } from '@/core/libs/toast';
import { CategoryEnum } from '@/modules/matchmaking/models/searchbar/enums/CategoryEnum';
import { SearchableConfiguration } from '@/modules/matchmaking/models/searchbar/SearchableConfiguration';
import { AnalyzedPartNamed, Workpiece } from '@/modules/matchmaking/types';
import { isTurningPart, sortDimensions } from '@/modules/matchmaking/utils';
import { SearchableConfigurationFactory } from '@/modules/matchmaking/utils/factories/SearchableConfigurationFactory';
import { ProjectPart, ProjectRequest } from '@/generated/api';

const technologiesList: SearchableConfiguration[] =
  SearchableConfigurationFactory.getSearchableConfigurations(
    CategoryEnum.technologies,
  );

const materialsList: SearchableConfiguration[] =
  SearchableConfigurationFactory.getSearchableConfigurations(
    CategoryEnum.materials,
  );

const checkIfHasFileType = (
  workpiece: Workpiece,
  files: AnalyzedPartNamed[],
): boolean => {
  // Determine if the new files are PDFs or STEPs
  const hasPdf = files.some((file) => file.pdfFilename);
  const hasStep = files.some((file) => file.stepFilename);

  // Check if workpiece already has a PDF or STEP file
  const workpieceHasPdf = workpiece.files.some((file) => file.pdfFilename);
  const workpieceHasStep = workpiece.files.some((file) => file.stepFilename);

  // Return true if we're trying to add a PDF and workpiece already has one
  // or if we're trying to add a STEP and workpiece already has one
  return (hasPdf && workpieceHasPdf) || (hasStep && workpieceHasStep);
};

export function splitFilesToWorkpieces(
  files: AnalyzedPartNamed[],
  existingWorkpieces: Workpiece[] = [],
  targetWorkpieceId?: string,
  mode?: ProjectRequest.mode,
): Workpiece[] {
  const getFilename = (file: AnalyzedPartNamed): string =>
    file.name.split('.').slice(0, -1).join('.');

  // If we have a target workpiece and we're in ASSEMBLIES mode, try to add files to that workpiece if possible
  if (targetWorkpieceId && mode === ProjectRequest.mode.ASSEMBLIES) {
    const targetWorkpiece = existingWorkpieces.find(
      (w) => w.id === targetWorkpieceId,
    );

    if (targetWorkpiece) {
      // Separate files that can be added to target workpiece from those that need new workpieces
      const { filesToAdd, filesToCreateNew } = files.reduce<{
        filesToAdd: AnalyzedPartNamed[];
        filesToCreateNew: AnalyzedPartNamed[];
      }>(
        (acc, file) => {
          // Create a temporary workpiece that includes the files we've decided to add so far
          const tempWorkpiece = {
            ...targetWorkpiece,
            files: [...targetWorkpiece.files, ...acc.filesToAdd],
          };

          const hasExistingFileType = checkIfHasFileType(tempWorkpiece, [file]);

          if (hasExistingFileType) {
            acc.filesToCreateNew.push(file);
          } else {
            acc.filesToAdd.push(file);
          }
          return acc;
        },
        { filesToAdd: [], filesToCreateNew: [] },
      );

      // If we have files to add to target workpiece
      if (filesToAdd.length > 0) {
        const technologies = filesToAdd
          .map((file) => file.technologies)
          .flat()
          .filter((t) => technologiesList.some((tech) => tech.id === t.id));

        const materials = filesToAdd
          .map((file) => file.materials)
          .flat()
          .filter((m) => materialsList.some((mat) => mat.id === m.id));

        const dimensionsMm = filesToAdd
          .map((file) => file.dimensionsMm)
          .flat()
          .slice(0, 3);

        const isTurning = isTurningPart(technologies.map((t) => t.id));
        const sortedDimensions = sortDimensions(dimensionsMm, isTurning, true);

        // Update the target workpiece with the new files and their properties
        const updatedWorkpiece = {
          ...targetWorkpiece,
          files: [...targetWorkpiece.files, ...filesToAdd],
          technologies: [
            ...new Set([...targetWorkpiece.technologies, ...technologies]),
          ],
          materials: [...new Set([...targetWorkpiece.materials, ...materials])],
          dimensionsMm: sortedDimensions.length
            ? sortedDimensions
            : targetWorkpiece.dimensionsMm,
          showTechnologiesBadge:
            technologies.length > 0 || targetWorkpiece.showTechnologiesBadge,
          showMaterialsBadge:
            materials.length > 0 || targetWorkpiece.showMaterialsBadge,
          showDimensionsBadge:
            dimensionsMm.length > 0 || targetWorkpiece.showDimensionsBadge,
        };

        // If we have files that need new workpieces, create them using the original logic
        if (filesToCreateNew.length > 0) {
          // Create new workpieces for files that can't be added to target
          const fileMap: Record<string, AnalyzedPartNamed[]> = {};
          filesToCreateNew.forEach((file) => {
            const filename = getFilename(file);
            if (Object.prototype.hasOwnProperty.call(fileMap, filename)) {
              fileMap[filename] = [...fileMap[filename], file];
            } else {
              fileMap[filename] = [file];
            }
          });

          const newWorkpieces = Object.entries(fileMap).map(
            ([groupname, fileGroup]) => ({
              id: v4(),
              name: groupname,
              files: fileGroup,
              technologies: fileGroup
                .map((file) => file.technologies)
                .flat()
                .filter((t) =>
                  technologiesList.some((tech) => tech.id === t.id),
                ),
              materials: fileGroup
                .map((file) => file.materials)
                .flat()
                .filter((m) => materialsList.some((mat) => mat.id === m.id)),
              dimensionsMm: sortDimensions(
                fileGroup
                  .map((file) => file.dimensionsMm)
                  .flat()
                  .slice(0, 3),
                isTurningPart(
                  fileGroup
                    .map((file) => file.technologies)
                    .flat()
                    .map((t) => t.id),
                ),
                true,
              ),
              quantities: [0],
              showTechnologiesBadge: fileGroup.some(
                (file) => file.technologies.length > 0,
              ),
              showMaterialsBadge: fileGroup.some(
                (file) => file.materials.length > 0,
              ),
              showDimensionsBadge: fileGroup.some(
                (file) => file.dimensionsMm.length > 0,
              ),
            }),
          );

          // Return the updated target workpiece plus the new workpieces
          return [
            ...existingWorkpieces.map((w) =>
              w.id === targetWorkpieceId ? updatedWorkpiece : w,
            ),
            ...newWorkpieces,
          ];
        }

        // If we only had files to add to target workpiece, return updated workpieces
        return existingWorkpieces.map((w) =>
          w.id === targetWorkpieceId ? updatedWorkpiece : w,
        );
      }
    }
  }

  // If no target workpiece, or no files could be added to target, use original grouping logic
  const fileMap: Record<string, AnalyzedPartNamed[]> = {};
  files.forEach((file) => {
    const filename = getFilename(file);
    if (Object.prototype.hasOwnProperty.call(fileMap, filename)) {
      fileMap[filename] = [...fileMap[filename], file];
    } else {
      fileMap[filename] = [file];
    }
  });

  const newWorkpieces = Object.entries(fileMap).map(
    ([groupname, fileGroup]) => {
      const existingWorkpieceIndex = existingWorkpieces
        .filter((w) => w.files?.length > 0)
        .findIndex((w) => w.files.some((f) => getFilename(f) === groupname));

      const technologies = fileGroup
        .map((file) => file.technologies)
        .flat()
        .filter((t) => technologiesList.some((tech) => tech.id === t.id));

      const materials = fileGroup
        .map((file) => file.materials)
        .flat()
        .filter((m) => materialsList.some((mat) => mat.id === m.id));

      const dimensionsMm = fileGroup
        .map((file) => file.dimensionsMm)
        .flat()
        .slice(0, 3);

      // Business logic: Longest dimension first for assigning to length
      const isTurning = isTurningPart(technologies.map((t) => t.id));
      const sortedDimensions = sortDimensions(dimensionsMm, isTurning, true);

      if (existingWorkpieceIndex !== -1) {
        const workpieceToUpdate = existingWorkpieces[existingWorkpieceIndex];
        if (checkIfHasFileType(workpieceToUpdate, fileGroup)) {
          toast({
            appearance: 'danger',
            message: staticIntl.instance.$t({
              id: 'CreateRequest.workpieces.errors.existingFile',
            }),
          });
          return workpieceToUpdate;
        }
        return {
          ...workpieceToUpdate,
          files: [...workpieceToUpdate.files, ...fileGroup],
          technologies: [
            ...new Set([...workpieceToUpdate.technologies, ...technologies]),
          ],
          materials: [
            ...new Set([...workpieceToUpdate.materials, ...materials]),
          ],
          dimensionsMm: sortedDimensions.length
            ? sortedDimensions
            : workpieceToUpdate.dimensionsMm,
          showTechnologiesBadge:
            technologies.length > 0 || workpieceToUpdate.showTechnologiesBadge,
          showMaterialsBadge:
            materials.length > 0 || workpieceToUpdate.showMaterialsBadge,
          showDimensionsBadge:
            dimensionsMm.length > 0 || workpieceToUpdate.showDimensionsBadge,
        } satisfies Workpiece;
      } else {
        return {
          id: v4(),
          name: groupname,
          files: fileGroup,
          technologies,
          materials,
          dimensionsMm: sortedDimensions,
          quantities: [0],
          showTechnologiesBadge: technologies.length > 0,
          showMaterialsBadge: materials.length > 0,
          showDimensionsBadge: dimensionsMm.length > 0,
        } satisfies Workpiece;
      }
    },
  );

  return [
    ...existingWorkpieces.filter(
      (w) =>
        w.files?.length > 0 &&
        !newWorkpieces.some((nw) => nw.name === getFilename(w.files[0])),
    ),
    ...newWorkpieces,
  ];
}

export function partToWorkpiece(part: ProjectPart): Workpiece {
  let stepFile: AnalyzedPartNamed | undefined = undefined;
  let pdfFile: AnalyzedPartNamed | undefined = undefined;

  const dimensionsMm: [number, number, number] | [] = part.length
    ? [part.length, part.width ?? 0, part.height ?? 0]
    : [];
  const technologies = (part.technologies ?? []).map((tech) => ({ id: tech }));
  const materials = part.materials.map((material) => ({
    id: material.material,
    designation: material.designation,
  }));
  const assemblyPartFiles = part.assemblyPartFiles?.map((assemblyPart) => ({
    name: assemblyPart.name,
    url: assemblyPart.url,
  }));

  const commonPart: Pick<
    AnalyzedPartNamed,
    | 'technologies'
    | 'materials'
    | 'dimensionsMm'
    | 'quantity'
    | 'tolerances'
    | 'surfaceTolerances'
    | 'surfaceTreatments'
    | 'volumeCm3'
    | 'turningDiameterMm'
  > = {
    technologies,
    materials,
    dimensionsMm,
    turningDiameterMm: part.diameter || undefined,
    tolerances: [],
    surfaceTolerances: [],
    surfaceTreatments: [],
    volumeCm3: 0,
    quantity: 0,
  };

  if (part.stepFile) {
    stepFile = {
      id: v4(),
      name: part.stepFile.name,
      stepFilename: part.stepFile.name,
      stepUrl: part.stepFile.url,
      glbUrl: part.glbUrl!,
      imageUrl: part.pngUrl!,
      ...commonPart,
    };
  }
  if (part.pdfFile) {
    pdfFile = {
      id: v4(),
      name: part.pdfFile.name,
      pdfFilename: part.pdfFile.name,
      pdfUrl: part.pdfFile.url,
      imageUrl: part.pngUrl!,
      pdfPreviewImg: pdfThumbnail(part.pdfFile.url, 'rfq'),
      ...commonPart,
    };
  }

  return {
    dimensionsMm,
    materials,
    technologies,
    id: v4(),
    showTechnologiesBadge: false,
    showDimensionsBadge: false,
    showMaterialsBadge: false,
    name: part.name!,
    quantities: part.quantities,
    files: [stepFile, pdfFile].filter(Boolean) as AnalyzedPartNamed[],
    assemblyPartFiles,
  };
}
