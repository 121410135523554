import { useQuery } from '@tanstack/react-query';

import {
  AnalyticsService,
  CompaniesService,
  InvitationsService,
  UsersService,
} from '@/generated/api';

export function useInvitationsThisMonth(isLoggedIn: boolean) {
  return useQuery({
    queryKey: ['invitationsThisMonth'],
    enabled: isLoggedIn,
    queryFn: () =>
      InvitationsService.invitationsControllerCountFreeInvitationsThisMonth(),
  });
}

export function useGetManufacturerAnalytics() {
  return useQuery({
    queryKey: ['getManufacturerAnalytics'],
    queryFn: () => AnalyticsService.analyticsControllerUserAnalytics(),
  });
}

export function useUserNotificationSettings() {
  return useQuery({
    queryKey: ['userNotificationSettings'],
    queryFn: () => UsersService.userControllerGetEmailSettings(),
  });
}

export function usePublicNotificationSettings(email: string, token: string) {
  return useQuery({
    queryKey: ['publicNotificationSettings', email, token],
    queryFn: () =>
      UsersService.userControllerGetUnsignedUserSettings(email, token),
  });
}

export function useGetPreferences() {
  return useQuery({
    queryKey: ['getCompanyPreferences'],
    queryFn: () =>
      CompaniesService.companyControllerGetPreferences().then((res) => {
        if (
          res.minPreferedQuantities === null &&
          res.maxPreferedQuantities === null
        ) {
          res.maxPreferedQuantities = Number.POSITIVE_INFINITY;
          res.minPreferedQuantities = Number.POSITIVE_INFINITY;
        }
        if (res.maxPreferedQuantities === null)
          res.maxPreferedQuantities = Number.POSITIVE_INFINITY;
        return res;
      }),
  });
}
