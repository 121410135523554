import { Workpiece } from '@/modules/matchmaking/types';
import { Project, ProjectRequest } from '@/generated/api';
import { FileInput } from '@/generated/types';

type ReducerType = Partial<ProjectRequest>;

export type RFQ_REQUEST_ACTION_TYPE =
  | { type: 'SET_INITIAL_STATE'; payload: Project | null }
  | {
      type: 'SET_INITIAL_STATE_WITH_TITLE_AND_MODE';
      payload: { title: string; mode: ProjectRequest.mode };
    }
  | {
      type: 'SET_CERTIFICATIONS';
      payload: string[];
    }
  | {
      type: 'SET_TENDER_AREA';
      payload: string[];
    }
  | {
      type: 'SET_TITLE_AND_MODE';
      payload: {
        title: string;
        mode: ProjectRequest.mode;
      };
    }
  | {
      type: 'SET_PARTS';
      payload: Workpiece[];
    }
  | {
      type: 'SET_ADDITIONAL_FILES';
      payload: {
        additionalFiles: FileInput[] | undefined;
      };
    }
  | {
      type: 'SET_IS_NDA_PROTECTED';
      payload: boolean;
    }
  | {
      type: 'SET_NDA_FILE';
      payload: FileInput | undefined;
    }
  | {
      type: 'SET_REQUIREMENTS';
      payload: Pick<
        ProjectRequest,
        | 'deadline'
        | 'reasonForRequest'
        | 'incoterms'
        | 'languages'
        | 'currency'
        | 'projectId'
        | 'deliveryDate'
        | 'note'
      >;
    }
  | {
      type: 'SET_PUBLICATION_DATA';
      payload: Pick<
        ProjectRequest,
        | 'selectedManufacturers'
        | 'publishAs'
        | 'certifications'
        | 'tenderArea'
        | 'isPrivate'
      >;
    };

export const rfqRequestReducerInitialState: ReducerType = {
  title: '',
  deadline: undefined,
  languages: [],
  reasonForRequest: undefined, // we need to set this to undefined because the "Additional Requirements" form required this field to not be populated the first time
  isNdaProtected: false,
  isPrivate: false,
  deliveryDate: undefined,
  projectId: '',
  note: '',
  incoterms: undefined,
  currency: undefined,
  parts: [],
  selectedManufacturers: [],
  additionalFiles: [],
  ndaFile: undefined,
  certifications: [],
  materials: [],
  tenderArea: [],
  publishAs: null,
};

export function rfqRequestReducer(
  state: typeof rfqRequestReducerInitialState,
  { type, payload }: RFQ_REQUEST_ACTION_TYPE,
): ReducerType {
  switch (type) {
    case 'SET_INITIAL_STATE':
      return !payload
        ? {
            ...rfqRequestReducerInitialState,
          }
        : {
            title: payload.title,
            additionalFiles:
              payload.additionalFiles?.map((file) => ({
                name: file.name,
                url: file.url,
              })) ?? [],
            isNdaProtected: payload.isNdaProtected,
            ndaFile: payload.ndaFile
              ? { name: payload.ndaFile.name, url: payload.ndaFile.url }
              : undefined,
            incoterms: payload.incoterms,
            languages: payload.languages,
            currency: payload.currency,
            note: payload.note,
            tenderArea: payload.tenderArea,
            certifications: payload.certifications,
            reasonForRequest: payload.reasonForRequest,
            mode: payload.mode,
          };
    case 'SET_INITIAL_STATE_WITH_TITLE_AND_MODE':
      return {
        ...rfqRequestReducerInitialState,
        title: payload.title,
        mode: payload.mode,
      };
    case 'SET_CERTIFICATIONS':
      return {
        ...state,
        certifications: payload,
      };
    case 'SET_TENDER_AREA':
      return {
        ...state,
        tenderArea: payload,
      };
    case 'SET_TITLE_AND_MODE':
      return {
        ...state,
        title: payload.title,
        mode: payload.mode,
      };
    case 'SET_PARTS':
      return {
        ...state,
        parts: payload.map((workpiece: Workpiece) => {
          const { name, quantities } = workpiece;
          const stepFile = workpiece.files.find(
            (f) => f.stepFilename && f.stepUrl,
          );
          const pdfFile = workpiece.files.find(
            (f) => f.pdfFilename && f.pdfUrl,
          );
          return {
            name,
            quantities,
            stepFile:
              stepFile && stepFile.stepFilename && stepFile.stepUrl
                ? {
                    name: stepFile.name,
                    url: stepFile.stepUrl,
                  }
                : null,
            pdfFile:
              pdfFile && pdfFile.pdfFilename && pdfFile.pdfUrl
                ? {
                    name: pdfFile.name,
                    url: pdfFile.pdfUrl,
                  }
                : null,
            length: workpiece.dimensionsMm?.[0] ?? null, // Always length is the first dimension and the longest
            width: workpiece.dimensionsMm?.[1] ?? null,
            height: workpiece.dimensionsMm?.[2] ?? null,
            diameter: null, // TODO: To discuss!
            technologies: workpiece.technologies?.map((tech) => tech.id),
            materials:
              workpiece.materials?.map((material) => ({
                id: material.id,
                designation: material.designation ?? null,
              })) ?? null,
            assemblyPartFiles:
              workpiece.assemblyPartFiles?.map((file) => ({
                name: file.name,
                url: file.url,
              })) ?? null,
          };
        }),
      };
    case 'SET_ADDITIONAL_FILES':
      return {
        ...state,
        additionalFiles: payload.additionalFiles ?? [],
      };
    case 'SET_IS_NDA_PROTECTED':
      return {
        ...state,
        isNdaProtected: payload,
        ndaFile: payload ? state.ndaFile : undefined,
      };
    case 'SET_NDA_FILE':
      return {
        ...state,
        ndaFile: payload,
      };
    case 'SET_REQUIREMENTS':
      return {
        ...state,
        deadline: payload.deadline,
        reasonForRequest: payload.reasonForRequest,
        incoterms: payload.incoterms,
        languages: payload.languages,
        currency: payload.currency,
        projectId: payload.projectId,
        deliveryDate: payload.deliveryDate,
        note: payload.note,
      };

    case 'SET_PUBLICATION_DATA':
      return {
        ...state,
        isPrivate: payload.isPrivate,
        publishAs: payload.publishAs,
        tenderArea: payload.tenderArea,
        certifications: payload.certifications,
        selectedManufacturers: payload.selectedManufacturers,
      };

    default:
      throw new Error('Invalid action type for rfqRequestReducer');
  }
}
