import mixpanel, { Dict } from 'mixpanel-browser';

import { errorHandler } from '@/core/libs/error-handler';
import TrackerEvents from '@/core/libs/event-tracker/TrackerEvents.enum';

class EventTracker {
  private initialized = false;
  private optOutTracking: boolean | undefined;
  private readonly printConsole =
    import.meta.env.MODE !== 'production' && import.meta.env.MODE !== 'test';

  constructor() {
    this.init();
  }

  init(): void {
    if (import.meta.env.PUBLIC_MIXPANEL_TOKEN === 'disabled') return;
    try {
      mixpanel.init(import.meta.env.PUBLIC_MIXPANEL_TOKEN, {
        persistence: 'localStorage',
        api_host: 'https://api-eu.mixpanel.com',
        record_sessions_percent:
          import.meta.env.MODE === 'production' ? 100 : 1,
        record_mask_text_selector: 'input[type="password"]',
        debug: !!this.printConsole,
      });
      this.updateUtm();
      //@ts-ignore
      if (!window.Cookiebot) window.Cookiebot = {};
      this.initialized = true;
      this.optOutTracking = undefined;
      this.updateConsent();
    } catch (error) {
      errorHandler.capture(error, { avoidFlashMessage: true });
    }
  }

  private updateUtm(): void {
    const utmKeys = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      'utm_content',
    ];
    const utmParams: Dict = {};
    const utmFirstParams: Dict = {};

    const queryString = window.location.toString().split('?');
    if (queryString.length > 1) {
      const queryParams = new URLSearchParams(queryString[1]);
      utmKeys.forEach((utmKey) => {
        const utmParam = queryParams.get(utmKey);
        if (utmParam) {
          utmParams[utmKey + ' [last touch]'] = utmParam;
          utmFirstParams[utmKey + ' [first touch]'] = utmParam;
          queryParams.delete(utmKey);
        }
      });
      mixpanel.people.set(utmParams);
      mixpanel.people.set_once(utmFirstParams);
      mixpanel.register(utmParams);
    }
  }

  private updateConsent(): void {
    // Prevent app from crashing if user uses AdBlocker
    if (!Cookiebot?.consent) return;
    if (
      this.initialized &&
      this.optOutTracking !== !Cookiebot.consent.statistics
    ) {
      this.optOutTracking = !Cookiebot.consent.statistics;
      if (this.optOutTracking) {
        if (this.printConsole) {
          // eslint-disable-next-line no-console
          console.log(`User has opted out tracking`);
        }
        mixpanel.opt_out_tracking();
      } else {
        if (this.printConsole) {
          // eslint-disable-next-line no-console
          console.log(`User has opted in tracking`);
        }
        mixpanel.opt_in_tracking();
      }
    }
  }

  track(eventName: TrackerEvents, eventProps?: Dict): void {
    if (import.meta.env.PUBLIC_MIXPANEL_TOKEN === 'disabled') return;
    this.updateConsent();
    if (Cookiebot?.hasResponse) {
      try {
        mixpanel.track(eventName, eventProps);
      } catch (error) {
        errorHandler.capture(error, { avoidFlashMessage: true });
      }
    }
  }
}

const eventTracker = new EventTracker();

export default eventTracker;
