import { createContext, useContext, useEffect, useRef, useState } from 'react';

import { OpenAPI, User } from '@/generated/api';

import GhostModeLayout from '../components/layout/ghost-mode-layout';
import { useAuthenticationContext } from './AuthenticationContext';

export interface GhostModeContext {
  impersonatedUser: User | null;
  isLoadingImpersonation: boolean;
  setImpersonatedUser: React.Dispatch<React.SetStateAction<User | null>>;
}

const GhostModeContext = createContext<GhostModeContext>({
  impersonatedUser: null,
  isLoadingImpersonation: false,
  setImpersonatedUser: {} as React.Dispatch<React.SetStateAction<User | null>>,
});

export interface GhostModeContextProviderProps {
  children: JSX.Element;
}

export function GhostModeProvider({
  children,
}: GhostModeContextProviderProps): JSX.Element {
  const { currentUser, getUserData } = useAuthenticationContext();
  const [impersonatedUser, setImpersonatedUser] = useState<User | null>(null);
  const [isLoadingImpersonation, setIsLoadingImpersonation] = useState(false);
  const prevImpersonatedUser = useRef<User | null>(null);

  useEffect(() => {
    if (!impersonatedUser && currentUser?.role === User.role._0) {
      const impersonatedUserFromLocalStorage =
        localStorage.getItem('impersonatedUser');
      if (impersonatedUserFromLocalStorage)
        setImpersonatedUser(
          JSON.parse(impersonatedUserFromLocalStorage) as User,
        );
    }
  }, [currentUser, getUserData, impersonatedUser]);

  useEffect(() => {
    const loadImpersonatedUser = async (): Promise<void> => {
      setIsLoadingImpersonation(true);
      if (impersonatedUser) {
        localStorage.setItem(
          'impersonatedUser',
          JSON.stringify(impersonatedUser),
        );
        prevImpersonatedUser.current = impersonatedUser;
        OpenAPI.HEADERS = {
          ...OpenAPI.HEADERS,
          'of-impersonated-user': impersonatedUser.id.toString(),
        };
      } else {
        if (OpenAPI.HEADERS) {
          const { 'of-impersonated-user': _, ...rest } =
            OpenAPI.HEADERS as Record<string, string>;
          OpenAPI.HEADERS = rest;
        }

        if (prevImpersonatedUser.current) {
          prevImpersonatedUser.current = null;
          localStorage.removeItem('impersonatedUser');
        }
      }
      await getUserData().finally(() => setIsLoadingImpersonation(false));
    };
    loadImpersonatedUser();
  }, [getUserData, impersonatedUser]);

  return (
    <GhostModeContext.Provider
      value={{
        impersonatedUser,
        isLoadingImpersonation,
        setImpersonatedUser,
      }}
    >
      <GhostModeLayout>{children}</GhostModeLayout>
    </GhostModeContext.Provider>
  );
}

export function useGhostModeContext(): GhostModeContext {
  return useContext(GhostModeContext);
}
