import { useState } from 'react';

import { useAuthenticationContext } from '@/core/context/AuthenticationContext';
import { errorHandler } from '@/core/libs/error-handler';
import { SearchQuery } from '@/modules/matchmaking/models/searchbar/SearchQuery';
import { getManufacturerRecommendations } from '@/modules/matchmaking/services/get-manufacturer-recommendations';
import {
  AnalyzedPartNamed,
  MatchedManufacturer,
  MatchedManufacturerLight,
  Workpiece,
} from '@/modules/matchmaking/types';
import { BookmarkManufacturersDTO, ProjectRequest } from '@/generated/api';

export interface UseRefetchManufacturersForWorkpieces {
  fetchManufacturers(
    blockedManufacturers: BookmarkManufacturersDTO[],
    projectMode: ProjectRequest.mode,
    signal?: AbortSignal,
  ): Promise<{
    manufacturers: (MatchedManufacturer | MatchedManufacturerLight)[];
    formattedParts: AnalyzedPartNamed[];
    suggestedManufacturers: MatchedManufacturer[];
  } | null>;
  refetchManufacturersWithNewSearchQuery(
    newQuery: Partial<SearchQuery>,
  ): Promise<{
    manufacturers: (MatchedManufacturer | MatchedManufacturerLight)[];
    suggestedManufacturers: MatchedManufacturer[];
  }>;
  isLoading: boolean;
}

export function useRefetchManufacturersForWorkpieces(
  workpieceList: Workpiece[],
  searchQuery: Partial<SearchQuery>,
): UseRefetchManufacturersForWorkpieces {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { currentUser } = useAuthenticationContext();
  const [formattedParts, setFormattedParts] = useState<AnalyzedPartNamed[]>([]);

  const reconcilePdfAndStpAnalyzedParts = (): AnalyzedPartNamed[] => {
    const reconciledPdfAndStpAnalyzedParts: AnalyzedPartNamed[] = [];
    workpieceList.forEach((workpiece) => {
      const stepFile = workpiece.files.find((file) => file.stepFileToken);
      const pdfFile = workpiece.files.find((file) => file.pdfFileToken);
      reconciledPdfAndStpAnalyzedParts.push({
        ...(stepFile
          ? {
              ...stepFile,
            }
          : {
              imageUrl: '',
              glbUrl: '',
              surfaceTolerances: [],
              volumeCm3: 0,
            }),
        id: workpiece.files[0].id,
        tolerances: workpiece.files[0].tolerances,
        surfaceTreatments: workpiece.files[0].surfaceTreatments,
        pdfFileToken: pdfFile?.pdfFileToken,
        pdfUrl: pdfFile?.pdfUrl,
        pdfFilename: pdfFile?.pdfFilename,
        pdfPreviewImg: pdfFile?.pdfPreviewImg,
        quantity: workpiece.quantities.sort((a, b) => b - a)?.[0] ?? 1,
        name:
          workpiece?.name ??
          pdfFile?.pdfFilename ??
          stepFile?.stepFilename ??
          '',
        technologies: workpiece?.technologies ?? [],
        materials: workpiece?.materials ?? workpiece.files[0].materials ?? [],
        dimensionsMm:
          (workpiece?.dimensionsMm as [number, number, number]) ?? undefined,
      });
    });
    return reconciledPdfAndStpAnalyzedParts;
  };

  const fetchManufacturers = async (
    blockedManufacturers: BookmarkManufacturersDTO[],
    projectMode: ProjectRequest.mode,
    signal?: AbortSignal,
  ): Promise<{
    manufacturers: (MatchedManufacturer | MatchedManufacturerLight)[];
    formattedParts: AnalyzedPartNamed[];
    suggestedManufacturers: MatchedManufacturer[];
  } | null> => {
    try {
      setIsLoading(true);
      const reconciledPdfAndStpAnalyzedParts =
        reconcilePdfAndStpAnalyzedParts();

      setFormattedParts(reconciledPdfAndStpAnalyzedParts);

      const { excludedManIds, excludedManUrls } = blockedManufacturers.reduce(
        (acc, bm) => {
          if (bm.companyId) {
            acc.excludedManIds.push(bm.companyId.toString());
          } else if (bm.encryptedUrl) {
            acc.excludedManUrls.push(bm.encryptedUrl);
          }
          return acc;
        },
        {
          excludedManIds: [] as string[],
          excludedManUrls: [] as string[],
        },
      );

      const allManufacturers = await getManufacturerRecommendations(
        reconciledPdfAndStpAnalyzedParts,
        searchQuery,
        undefined, // we set pageSize undefined because we want to get all manufacturers
        currentUser?.company?.website ?? undefined,
        currentUser?.company?.id?.toString(),
        excludedManUrls,
        excludedManIds,
        true,
        signal,
        projectMode,
      );

      return {
        manufacturers: allManufacturers.manufacturers,
        formattedParts: reconciledPdfAndStpAnalyzedParts,
        suggestedManufacturers:
          allManufacturers.suggestedManufacturers as MatchedManufacturer[],
      };
    } catch (error) {
      const isAborted = error instanceof Error && error.name === 'AbortError';
      if (!isAborted) {
        errorHandler.capture(error);
      }
    } finally {
      setIsLoading(false);
    }
    return null;
  };

  const refetchManufacturersWithNewSearchQuery = async (
    newQuery: Partial<SearchQuery>,
  ): Promise<{
    manufacturers: (MatchedManufacturer | MatchedManufacturerLight)[];
    suggestedManufacturers: MatchedManufacturer[];
  }> => {
    try {
      setIsLoading(true);

      const allManufacturers = await getManufacturerRecommendations(
        formattedParts,
        {
          ...searchQuery,
          ...newQuery,
        },
        undefined, // we set pageSize undefined because of the last parameter "true" that makes the search to return all manufacturers
        currentUser?.company?.website ?? undefined,
        currentUser?.company?.id?.toString(),
        undefined,
        undefined,
        true,
      );

      return {
        manufacturers: allManufacturers.manufacturers,
        suggestedManufacturers:
          allManufacturers.suggestedManufacturers as MatchedManufacturer[],
      };
    } catch (err) {
      errorHandler.capture(err);
    } finally {
      setIsLoading(false);
    }
    return {
      manufacturers: [],
      suggestedManufacturers: [],
    };
  };

  return {
    fetchManufacturers,
    refetchManufacturersWithNewSearchQuery,
    isLoading,
  };
}
