import Gleap from 'gleap';

export function initGleap(): void {
  // This env only split the bug report between dev and prod
  // But nothing else, it does not split the users or anything else
  Gleap.setEnvironment(process.env.NODE_ENV === 'development' ? 'dev' : 'prod');

  // Please make sure to call this method only once!
  import.meta.env.PUBLIC_GLEAP &&
    Gleap.initialize(import.meta.env.PUBLIC_GLEAP);
}
