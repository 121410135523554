/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { AddOnboardingStepRequest } from '../models/AddOnboardingStepRequest';
import type { Company } from '../models/Company';
import type { CompanyCapabilitiesDto } from '../models/CompanyCapabilitiesDto';
import type { CompanyPreferences } from '../models/CompanyPreferences';
import type { CompanyPreferencesDto } from '../models/CompanyPreferencesDto';
import type { CompanyPublicProfileDTO } from '../models/CompanyPublicProfileDTO';
import type { CompanyStatusDto } from '../models/CompanyStatusDto';
import type { DeleteBulkSamplePartRequest } from '../models/DeleteBulkSamplePartRequest';
import type { DisableSubscriptionRequest } from '../models/DisableSubscriptionRequest';
import type { MachineDto } from '../models/MachineDto';
import type { MachineRequest } from '../models/MachineRequest';
import type { OnboardingStep } from '../models/OnboardingStep';
import type { SamplePartDto } from '../models/SamplePartDto';
import type { Subscription } from '../models/Subscription';
import type { UpdatePublicProfileRequest } from '../models/UpdatePublicProfileRequest';
import type { UpdateUserCompanyRequest } from '../models/UpdateUserCompanyRequest';

export class CompaniesService {
  /**
   * @param requestBody
   * @returns string
   * @throws ApiError
   */
  public static companyControllerUpdateCompany(
    requestBody: UpdateUserCompanyRequest,
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/companies',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param publicMail
   * @param name
   * @param url
   * @returns string Company Id.
   * @throws ApiError
   */
  public static companyControllerCompanyExists(
    publicMail?: string,
    name?: string,
    url?: string,
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/companies/exists',
      query: {
        publicMail: publicMail,
        name: name,
        url: url,
      },
      errors: {
        404: `Company not found.`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns Company
   * @throws ApiError
   */
  public static companyControllerUpdateSettings(
    requestBody: UpdatePublicProfileRequest,
  ): CancelablePromise<Company> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/companies/public-profile',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param requestBody
   * @returns any
   * @returns OnboardingStep
   * @throws ApiError
   */
  public static companyControllerAddOnboardingStep(
    requestBody: AddOnboardingStepRequest,
  ): CancelablePromise<any | OnboardingStep> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/companies/onboarding-status',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Company not found.`,
      },
    });
  }

  /**
   * @param uuid
   * @returns CompanyPublicProfileDTO
   * @throws ApiError
   */
  public static companyControllerGetProfile(
    uuid: string,
  ): CancelablePromise<CompanyPublicProfileDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/companies/{uuid}/profile',
      path: {
        uuid: uuid,
      },
    });
  }

  /**
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static companyControllerDeletePromotionalImage(
    id: number,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/companies/promotional-images/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns CompanyCapabilitiesDto
   * @throws ApiError
   */
  public static companyControllerGetCapabilities(): CancelablePromise<CompanyCapabilitiesDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/companies/capabilities',
    });
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static companyControllerUpdateCapabilities(
    requestBody: CompanyCapabilitiesDto,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/companies/capabilities',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns CompanyPreferences
   * @throws ApiError
   */
  public static companyControllerGetPreferences(): CancelablePromise<CompanyPreferences> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/companies/preferences',
    });
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static companyControllerUpdatePreferences(
    requestBody: CompanyPreferencesDto,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/companies/preferences',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns Subscription
   * @throws ApiError
   */
  public static subscriptionControllerGetSubscriptions(): CancelablePromise<
    Array<Subscription>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/companies/subscriptions',
    });
  }

  /**
   * @param id
   * @returns Subscription
   * @throws ApiError
   */
  public static subscriptionControllerEnableSubscription(
    id: number,
  ): CancelablePromise<Subscription> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/companies/subscriptions/{id}/enable',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id
   * @param requestBody
   * @returns Subscription
   * @throws ApiError
   */
  public static subscriptionControllerDisableSubscription(
    id: number,
    requestBody: DisableSubscriptionRequest,
  ): CancelablePromise<Subscription> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/companies/subscriptions/{id}/disable',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns SamplePartDto
   * @throws ApiError
   */
  public static samplePartsControllerFindSampleParts(): CancelablePromise<
    Array<SamplePartDto>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/companies/sample-parts',
    });
  }

  /**
   * @param requestBody
   * @returns SamplePartDto
   * @throws ApiError
   */
  public static samplePartsControllerUpsertSamplePart(
    requestBody: SamplePartDto,
  ): CancelablePromise<SamplePartDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/companies/sample-parts',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param requestBody
   * @returns SamplePartDto
   * @throws ApiError
   */
  public static samplePartsControllerDeleteSamplePartsBulk(
    requestBody: DeleteBulkSamplePartRequest,
  ): CancelablePromise<SamplePartDto> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/companies/sample-parts',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param filename
   * @returns SamplePartDto
   * @throws ApiError
   */
  public static samplePartsControllerDeleteSamplePart(
    filename: string,
  ): CancelablePromise<SamplePartDto> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/companies/sample-parts/{filename}',
      path: {
        filename: filename,
      },
    });
  }

  /**
   * @returns CompanyStatusDto
   * @throws ApiError
   */
  public static machineryControllerGetStatus(): CancelablePromise<CompanyStatusDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/companies/status',
    });
  }

  /**
   * @returns MachineDto
   * @throws ApiError
   */
  public static machineryControllerFindMachines(): CancelablePromise<
    Array<MachineDto>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/companies/machines',
    });
  }

  /**
   * @param requestBody
   * @returns MachineDto
   * @throws ApiError
   */
  public static machineryControllerCreateMachine(
    requestBody: MachineRequest,
  ): CancelablePromise<MachineDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/companies/machines',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param requestBody
   * @returns MachineDto
   * @throws ApiError
   */
  public static machineryControllerUpdateMachine(
    requestBody: MachineRequest,
  ): CancelablePromise<MachineDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/companies/machines',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param brand
   * @param model
   * @param reason
   * @returns MachineDto
   * @throws ApiError
   */
  public static machineryControllerDeleteMachine(
    brand: string,
    model: string,
    reason: string,
  ): CancelablePromise<MachineDto> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/companies/machines',
      query: {
        brand: brand,
        model: model,
        reason: reason,
      },
    });
  }
}
