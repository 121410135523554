import React from 'react';
import { router } from '@/router';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { TailwindIndicator } from '@/core/components/tailwind-indicator';
import { AuthenticationProvider } from '@/core/context/AuthenticationContext';
import { BreakpointProvider } from '@/core/context/BreakpointContext';
import { GhostModeProvider } from '@/core/context/GhostModeContext';
import { I18nProvider } from '@/core/context/I18nContext';
import { initGleap } from '@/core/libs/init/gleap.init';
import { ReactQueryProvider } from '@/core/libs/react-query-provider';
import { OpenAPI } from '@/generated/api';

import '@/core/styles/global.css';
import '@/core/styles/rsuite-overrides.css';
import '@/core/libs/init/dayjs.init';
import '@/core/styles/variables.css';
import '@/core/styles/animations.css';

OpenAPI.BASE = import.meta.env.PUBLIC_BACKEND_API;
window.dataLayer = window.dataLayer || [];

const supportsContainerQueries = 'container' in document.documentElement.style;
if (!supportsContainerQueries) {
  // @ts-ignore
  import('container-query-polyfill');
}

if (import.meta.env.PUBLIC_SENTRY_ENABLED !== 'false') {
  Sentry.init({
    dsn: import.meta.env.PUBLIC_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: import.meta.env.MODE,
  });
}

initGleap();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <BreakpointProvider>
      <I18nProvider>
        <ReactQueryProvider>
          <AuthenticationProvider>
            <GhostModeProvider>
              <>
                <RouterProvider router={router} />
                <TailwindIndicator />
              </>
            </GhostModeProvider>
          </AuthenticationProvider>
        </ReactQueryProvider>
      </I18nProvider>
    </BreakpointProvider>
  </React.StrictMode>,
);
